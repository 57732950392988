.purchaseInputForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

input {
  width: auto;
}

.buttonSubmit {
  max-width: 12rem;
}

@media screen and (min-width: 500px) {
  .buttonSubmit {
    max-width: 16rem;
  }
}
