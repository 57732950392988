.settingsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.inputTitle {
  margin-bottom: 0.4rem;
  margin-top: 1.4rem;
  text-align: center;
}
