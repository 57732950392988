.overviewBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  width: calc(100% - 5rem);
  height: 100%;
  max-width: 400px;
}

.buttonBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  margin-top: auto;
  padding: 2rem;
  padding-bottom: 3rem;
  padding-top: 0;
  box-sizing: border-box;
  flex-direction: row;
}

@media screen and (min-width: 500px) {
  .overviewBox {
    justify-content: center;
  }
}
