.budgetBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoText {
  font-family: 'Arimo', 'sans-serif';
  margin-top: 1rem;
}

.positiveBudget {
  font-size: 2.5rem;
  font-family: 'Arimo', 'sans-serif';
  font-weight: bold;
  letter-spacing: 2px;
  color: #00D83C;
}

.negativeBudget {
  font-size: 2.5rem;
  font-family: 'Arimo', 'sans-serif';
  font-weight: bold;
  letter-spacing: 2px;
  color: rgb(255, 71, 71);
}