html {
  box-sizing: border-box;
  font-size: 16px;
  letter-spacing: 0.5px;
  background: linear-gradient(180deg, #005669 0%, #007792 65%, #4594A5 100%);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #F4F4F4;
}

button, input[type="submit"] {
  font-family: 'Hammersmith One', 'sans-serif';
  font-size: 1rem;
  width: 100%;
  padding: 0.7rem 1rem;
  border: 2px solid #5296AC;
  border-radius: 20px;
  background-color: transparent;
  color: #F4F4F4;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

button:hover, input[type="submit"]:hover {
  background-color: #5296AC;
}

button:active, input[type="submit"]:active {
  box-shadow: none;
  transform: translate(0, 2px);
}

input {
  font-family: 'Hammersmith One', 'sans-serif';
  font-size: 1rem;
  text-align: center;
  width: 100%;
  padding: 0.3rem 0.5rem;
  border: none;
  border-radius: 20px;
  background-color: white;
  color: #676767;
  box-sizing: border-box;
}

input#fixed {
  width: auto;
}

@media screen and (min-width: 500px) {
  input {
    max-width: 200px;
  }

  button {
    max-width: 200px;
  }
}
