.welcomeBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 2rem;
  gap: 1rem;
  height: 100%;
}

.welcomeBox input, .welcomeBox button {
  max-width: 10rem;
}
