.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  padding: 0.5rem;
  border: 2px solid #5296AC;
  border-radius: 20px;
  font-family: 'Hammersmith One', sans-serif;
  min-width: 200px;
}

.historyBox {
  font-family: 'Arimo', 'sans-serif';
  display: flex;
  flex-direction: column;
  border-left: 1px solid #5296AC;
  margin: 0.5rem;
  padding: 0 0.5rem;
}

img {
  width: 1.5rem;
  color: white;
}
