.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.loginBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100%;
  width: calc(100% - 5rem);
  margin-bottom: 4rem;
  max-width: 400px;
}

.loginHeader {
  font-family: 'Hammersmith One', 'sans-serif';
  font-weight: 400;
}

.errorMessage {
  position: absolute;
  bottom: 3rem;
  color: rgb(255, 172, 172);
  font-size: 0.8rem;
  margin: 0 1rem;
  font-style: italic;
  text-align: center;
}

.buttonBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

@media screen and (min-width: 500px) {
  .buttonBox {
    margin-top: 1rem;
    flex-direction: row;
  }
}
