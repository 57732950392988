.menubar {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #5296AC;
}

.logoText {
  font-size: 1.5rem;
    font-family: 'Hammersmith One', sans-serif;
    color: #F4F4F4;
}
